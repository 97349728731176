import styled, { css } from "styled-components";

import { variables, media } from "../../../global-styles";

const Body = styled.div`
  background: ${(props) => props.theme.colors.background};
  transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  @media print {
    padding-top: 0px;
  }

  ${(props) =>
    props.specialHeader &&
    css`
      ${(props) =>
        props.statusBarHeight !== 0 &&
        css`
          padding-top: calc(
            ${variables.headerHeightMobile}+ ${props.statusBarHeight}px
          ) !important;
        `};
    `};

  ${(props) =>
    props.increaseBodyTopPadding &&
    css`
      padding-top: 8.1rem !important;

      ${(props) =>
        props.statusBarHeight !== 0 &&
        css`
          padding-top: calc(8.1rem + ${props.statusBarHeight}px) !important;
        `};
    `};

  ${(props) =>
    props.increaseBodyTopPadding &&
    props.notifications &&
    css`
      padding-top: 12.5rem !important;

      ${(props) =>
        props.statusBarHeight !== 0 &&
        css`
          padding-top: calc(12.5rem + ${props.statusBarHeight}px) !important;
        `};

      ${media.child`
        padding-top: 8.1rem !important;


        ${(props) =>
          props.statusBarHeight !== 0 &&
          css`
            padding-top: calc(8.1rem + ${props.statusBarHeight}px) !important;
          `};
      `};
    `};

  ${(props) =>
    props.isApp &&
    css`
      ${(props) =>
        props.statusBarHeight !== 0 &&
        css`
          padding-top: calc(
            ${variables.headerHeightMobile} + ${props.statusBarHeight}px
          ) !important;
        `};
    `};
`;

export default Body;
