import styled, { css } from "styled-components";

const Arrow = styled.div`
  transition: transform 0.2s ease;
  transform-origin: center;

  & svg {
    display: block;
  }

  /* Up version */
  ${(props) =>
    props.direction === "up" &&
    css`
      & svg {
        transform: rotate(180deg);
      }
    `};

  /* Left version */
  ${(props) =>
    props.direction === "left" &&
    css`
      & svg {
        transform: rotate(90deg);
      }
    `};

  /* Right version */
  ${(props) =>
    props.direction === "right" &&
    css`
      & svg {
        transform: rotate(-90deg);
      }
    `};

  /* Round version */
  ${(props) =>
    props.round &&
    css`
      padding: 0.6rem;
      background-color: ${props.theme.colors.grayLight};
      border-radius: 50%;
    `};

  /* Inline version */
  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
    `};

  /* special color version */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
`;

export default Arrow;
