import styled, { css } from "styled-components";
import fluidUnit from "../helpers/fluidUnit";

const H1 = styled.h1`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "32px",
    maxUnit: "48px",
  })};
  ${fluidUnit({
    unit: "line-height",
    minUnit: "34px",
    maxUnit: "51px",
  })};

  letter-spacing: 0.01em;
  font-weight: 600;
  color: ${(props) => props.theme.secondary.dark};

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.secondary[props.color]};
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  ${(props) =>
    props.themes &&
    css`
      color: ${(props) => props.theme.secondary.dark};
    `};

  ${(props) =>
    props.unselectable &&
    css`
      user-select: none;
    `}
`;

const H2 = styled.h2`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "24px",
    maxUnit: "32px",
  })};
  ${fluidUnit({
    unit: "line-height",
    minUnit: "26px",
    maxUnit: "34px",
  })};

  letter-spacing: 0.02em;
  font-weight: 600;

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  ${(props) =>
    props.themes &&
    css`
      color: ${(props) => props.theme.secondary.dark};
    `};

  ${(props) =>
    props.unselectable &&
    css`
      user-select: none;
    `}
  ${(props) =>
    props.strong &&
    css`
      font-weight: 700;
    `}

  ${(props) =>
    props.new &&
    css`
      font-weight: 600;
      ${fluidUnit({
        unit: "font-size",
        minUnit: "24px",
        maxUnit: "33px",
      })};

      ${fluidUnit({
        unit: "line-height",
        minUnit: "31px",
        maxUnit: "41.25px",
      })};
    `}
`;

const H3 = styled.h3`
  color: ${(props) => props.theme.secondary.dark};
  font-size: 24px;
  line-height: 26px;

  font-weight: 600;

  letter-spacing: 0.02em;

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* Primary variant */
  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.primary.green};
    `};

  ${(props) =>
    props.themes &&
    css`
      color: ${(props) => props.theme.secondary.dark};
    `};

  ${(props) =>
    props.unselectable &&
    css`
      user-select: none;
    `}

  ${(props) =>
    props.new &&
    css`
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      /* identical to box height, or 25px */

      letter-spacing: 0.02em;
    `}
`;
// TO_BE_REMOVED
const H4 = styled.h4`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "16px",
    maxUnit: "22px",
  })};
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.3px;
  color: ${(props) => props.theme.secondary.dark};

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};
`;
// TO_BE_REMOVED
const H5 = styled.h5`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "24px",
    maxUnit: "32px",
  })};
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.3px;

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};
  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.colors.gray700};
    `};
`;
// TO_BE_REMOVED
const H6 = styled.h6`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "20px",
    maxUnit: "28px",
  })};
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.3px;
  color: ${(props) => props.theme.secondary.dark};

  /* No-wrap version */
  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  ${(props) =>
    props.themes &&
    css`
      color: ${(props) => props.themes.colors.productCardTextColor};
    `};

  ${(props) =>
    props.light &&
    css`
      font-weight: 300;
    `};

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};
  /* color variant */
  ${(props) =>
    props.bright &&
    css`
      color: white;
    `};
`;

export { H1, H2, H3, H4, H5, H6 };
