import React from "react";
import Router from "next/router";
import isServer from "../../helpers/isServer";
import ServerLink from "../ServerLink";
import { Link } from "../../primitives";

const MagicLink = (props) => {
  const { as = "", to = {}, href = "" } = props;

  if (isServer) {
    return (
      <ServerLink {...props} href={to} as={as || (to && to.as) || null}>
        {props.children}
      </ServerLink>
    );
  }

  if (typeof to === "object") {
    return (
      <Link {...props} to={as || (to && to.as) || href}>
        {props.children}
      </Link>
    );
  }

  if (Router.router.pathname === "/mina-sidor/[[...slug]]") {
    return (
      <Link {...props} to={to || href}>
        {props.children}
      </Link>
    );
  }

  return (
    // <Link {...props} to={to || href}>
    //   {props.children}
    // </Link>
    <ServerLink {...props} href={to} as={as || (to && to.as) || null}>
      {props.children}
    </ServerLink>
  );
};

export default MagicLink;
