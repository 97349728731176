import styled, { css } from "styled-components";

const Price = styled.span`
  font-size: ${(props) => props.theme.typography.fontSizeGlobalMin};

  ${(props) =>
    props.discount &&
    css`
      color: ${(props) => props.theme.secondary.orange};
    `}

  ${(props) =>
    props.old &&
    css`
      text-decoration: line-through;
      color: ${(props) => props.theme.secondary.gray};
    `};

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `};
`;

export default Price;
