import styled, { css } from "styled-components";
import fluidUnit from "../helpers/fluidUnit";
import { media } from "../global-styles";

const Tag = styled.div`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "14px",
    maxUnit: "16px",
  })};
  display: inline-block;
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.grayLight};
  padding: 0.7em;
  border: 2px solid ${(props) => props.theme.colors.grayLight};
  border-radius: 30px;

  ${media.teen`
    padding: 1em;
  `};

  /* Active variant */
  ${(props) =>
    props.active &&
    css`
      color: white;
      background-color: ${props.theme.colors.primary};
      border: solid 2px ${props.theme.colors.primary};
    `};

  /* Disabled variant */
  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.grayDark};
      background: none;
      border: solid 2px ${props.theme.colors.grayMedium};
    `};
`;

const ButtonTag = Tag.withComponent("button");

export { Tag, ButtonTag };
