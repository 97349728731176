import styled, { css } from "styled-components";
// TO_BE_REMOVED
const Subtitle1 = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  font-weight: 600;

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
`;
// TO_BE_REMOVED
const Subtitle2 = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  font-weight: 600;

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
`;
// TO_BE_REMOVED
const Body1 = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `};

  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => props.theme.colors.primary};
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
  ${(props) =>
    props.center &&
    css`
      margin: 0 auto;
    `};
`;
// TO_BE_REMOVED
const Body2 = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.dark};

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
  ${(props) =>
    props.themes &&
    css`
      color: ${(props) => props.themes.colors.productCardTextColor};
    `};
`;
// TO_BE_REMOVED
const Body3 = styled.p`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  font-weight: 300;

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `};

  ${(props) =>
    props.inline &&
    css`
      display: inline;
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
`;
// TO_BE_REMOVED
const Caption = styled.p`
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.02em;
  font-weight: 500;

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
`;
// TO_BE_REMOVED
const Note = styled.p`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  /* color variant */
  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `};
`;

const Ingress = styled.p`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.2px;

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};

  ${(props) =>
    props.thin &&
    css`
      font-weight: 300;
    `};

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `};

  ${(props) =>
    props.size &&
    css`
      font-size: ${props.size};
      line-height: 24px;
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
`;
// TO_BE_REMOVED?
const Quote = styled.blockquote`
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.5px;
  font-style: italic;
  &:before {
    content: ${(props) => (!props.withoutMarks ? "open-quote;" : "none")};
  }
  &:after {
    content: ${(props) => (!props.withoutMarks ? "close-quote;" : "none")};
  }

  ${(props) =>
    props.thin &&
    css`
      font-weight: 300;
    `};

  ${(props) =>
    props.mini &&
    css`
      font-size: 24px;
      letter-spacing: 0.3px;
      line-height: 28px;
    `};

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
`;
// TO_BE_REMOVED
const Overline = styled.p`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;

  /* text-align prop */
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};

  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  ${(props) =>
    props.themes &&
    css`
      color: ${props.themes.colors.primaryDark};
    `};
`;
// TO_BE_REMOVED
const StyledText = styled.span`
  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `};
  ${(props) =>
    props.themes &&
    css`
      color: ${props.themes.colors[props.color]};
    `};
`;

const StrikeOut = styled.span`
  text-decoration: line-through;
`;

const Tag = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  ${(props) =>
    props.Strong &&
    css`
      font-weight: 600;
    `};
`;

const Price = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.02em;
`;
const Label = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
`;

export {
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  Body3,
  Caption,
  Note,
  Ingress,
  Quote,
  Overline,
  StyledText,
  StrikeOut,
  Tag,
  Price,
  Label,
};
