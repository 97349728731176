import { keyframes } from "styled-components";

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

const pop = keyframes`
  50% {
    transform: scale(1.9);
  }
`;

const appearFromTop = keyframes`
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;
const hover = keyframes`
  0% {
    transform: translate(0px);
  }
  25%{
    transform: translate(1.25px, -2.5px);
  }
  50%{
    transform: translate(2.5px, 0px);
  }
 75%{
  transform: translate(1.25px, 2.5px);
  }
  100% {
    transform: translate(0px);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInFromLeft = keyframes`
  0% {
    transform: translateX(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

const jumpy = keyframes`
  0%,
  to { transform: rotate(0);}
  2.5% { transform: scale(1.1);}
  5% { transform: translateY(-12px);}
  10% { transform: translateY(0);}
  15% { transform: translateY(-8px);}
  20% { transform: translateY(0);}
`;

const nudge = keyframes`
  0%,
  to { transform: rotate(0);}
  1.25% { transform: scale(1.1);}
  2.5% { transform: translateX(12px);}
  5% { transform: translateX(0);}
  7.5% { transform: translateX(8px);}
  10% { transform: translateX(0);}
`;

const wobble = keyframes`
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% { transform: translateX(-30px) rotate(-6deg);}
  30% { transform: translateX(15px) rotate(6deg);}
  45% { transform: translateX(-15px) rotate(-3.6deg);}
  60% { transform: translateX(9px) rotate(2.4deg);}
  75% { transform: translateX(-6px) rotate(-1.2deg);}
`;

const scaleInCenter = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const appear = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const draw = keyframes`
  0% {
    stroke-dashoffset: 660;
  }
  100% {
    stroke-dashoffset: -660;
  }
`;

const inFromLeft = keyframes`
  0% {
    transform: scale(0);
    transform-origin: 0% 50%;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
    opacity: 1;
  }
`;

const inFromTopLeft = keyframes`
  0% {
    transform: scale(0);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
`;

const inFromBottomRight = keyframes`
  0% {
    transform: scale(0);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
`;

const loadingGradient = keyframes`
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.5;
  }
  100% {
      opacity: 1;
  }
`;

const JumpOver = keyframes`
  0% {
    transform: translate(0, 70px);
    z-index: -1;
  }
  40% {
    transform: translate(0, -25px);
    z-index: 1;
  }
  55% {
    transform: translate(0, -15px);
    z-index: 1;
  }
  70% {
    transform: translate(0, -30px);
    z-index: 1;
  }
  100% {
    transform: translate(0, 0px);
    z-index: 1;
  }
`;

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const slideInFromRight = keyframes`
  0% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0px);
  }
`;

const slideOutToRight = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(500px);
  }
`;

export {
  draw,
  pulsate,
  jumpy,
  nudge,
  wobble,
  fadeIn,
  fadeInFromLeft,
  appearFromTop,
  scaleInCenter,
  rotate,
  appear,
  inFromLeft,
  inFromTopLeft,
  inFromBottomRight,
  loadingGradient,
  JumpOver,
  pop,
  hover,
  shake,
  slideInFromRight,
  slideOutToRight,
};
