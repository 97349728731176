import styled, { css } from "styled-components";

const Form = styled.form`
  display: block;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;

export default Form;
