import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/legacy/image";

import { Loader } from "../../primitives";

function LoaderContainer({
  isFullPage = false,
  isWholeScreen = false,
  text = "",
}) {
  return (
    <AnimatePresence>
      <motion.div
        key="logo-loader"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          type: "spring",
        }}
        className="flex-grow"
      >
        <Loader isFullPage={isFullPage} isWholeScreen={isWholeScreen}>
          <div className="relative w-48 h-20 md:w-72 animate-bounce">
            <Image
              src="https://ggfrontendassets.azureedge.net/publicassets/linasmatkasse/linas-logo-red.svg"
              alt="Linas Matkasse"
              layout="fill"
              objectFit="contain"
            />
          </div>

          {text && (
            <div className="px-8 mt-4 text-center">
              <span>{text}</span>
            </div>
          )}
        </Loader>
      </motion.div>
    </AnimatePresence>
  );
}

export default LoaderContainer;
