import styled, { css } from "styled-components";
import { variables } from "../global-styles";

const Hr = styled.hr`
  display: block;
  // height: ${(props) => props.border || variables.borderWidth};
  margin: 0;
  background-color: ${(props) => props.theme.primary.green};
  border: 0;
  width: 100%;

  /* special color version */
  ${(props) =>
    props.dark &&
    css`
      background-color: ${props.theme.colors.dark};
    `};

  /* special color version */
  ${(props) =>
    props.special &&
    css`
      background-color: ${props.theme.colors.special};
    `};

  /* grayLight color version */
  ${(props) =>
    props.weak &&
    css`
      background-color: ${props.theme.secondary.lightGray};
    `};

  /* Primary color version */
  ${(props) =>
    props.primary &&
    css`
      background-color: ${(props) => props.theme.primary.green};
    `};

  /* Primary color version */
  ${(props) =>
    props.primaryLight &&
    css`
      background-color: ${(props) => props.theme.primary.green3};
    `};

  /* Thin color version */
  ${(props) =>
    props.thin &&
    css`
      height: 1px;
    `};

  ${(props) =>
    props.medium &&
    css`
      height: 2px !important;
      border: 1.5px;
    `};

  ${(props) =>
    props.newGray &&
    css`
      background-color: #898989;
    `};
`;

export default Hr;
