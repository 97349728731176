import styled, { css } from "styled-components";

import { shake } from "../helpers/animations";

const Animate = styled.div`
  position: relative;

  ${(props) =>
    props.shake &&
    css`
      animation: ${shake} 0.4s 3 ease-in-out 0.5s both;
    `};

  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
    `};

  ${(props) =>
    props.animateFrom &&
    css`
      ${props.animateFrom};
    `};

  ${(props) =>
    props.animate &&
    css`
      transition: ${props.animate} ${props.animateDuration || "500"}ms ease-out;
    `};

  ${(props) =>
    props.animateTo &&
    css`
      ${props.animateTo};
    `};
`;

export default Animate;
