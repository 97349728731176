/**
 * Baseline number.
 */

const baseNumberSmall = 4;
const baseNumber = 10;
const gutter = 4;

const variables = {
  spacing: {
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "24px",
    6: "32px",
    7: "40px",
    8: "56px",
    9: "64px",
    10: "72px",
  },
  screen: {
    small: `(min-width: 320px)`,
    medium: `(min-width: 768px)`,
    large: `(min-width: 1024px)`,
    huge: `(min-width: 1337px)`,
    mediumMax: `(max-width: 768px)`,
    largeMax: `(max-width: 1024px)`,
  },
  fontSize: {
    h1: {
      desktop: "48px",
      mobile: "32px",
    },
    h2: {
      desktop: "32px",
      mobile: "24px",
    },
    h3: {
      desktop: "24px",
      mobile: "18px",
    },
    caption: "10px",
    small: "12px",
    regular: "14px",
    large: "16px",
    larger: "18px",
  },
  maxWidth: "1440px",
  gutter,

  /**
   * gutters (Horizontal)
   */

  gutterSuperTightMobile: `${baseNumberSmall / 2}vw`,
  gutterTightMobile: `${baseNumberSmall / 1}vw`,
  // gutterMediumMobile: '8px',
  gutterMediumMobile: `${baseNumberSmall / 1}vw`,
  gutterLargeMobile: `${baseNumberSmall / 0.5}vw`,

  gutterTight: `${baseNumber / 8}vw`,
  gutterMedium: "24px",
  // gutterMedium: `${baseNumber / 3}vw`,
  gutterLarge: `${baseNumber / 1}vw`,

  gutter2Mobile: "16px",
  gutter2SmallMobile: "16px",

  /**
   * spacing (Vertical)
   */

  spacingTightMobile: `${baseNumberSmall / 1.2}vw`,
  spacingSmallMobile: `${baseNumberSmall / 0.9}vw`,
  spacingMediumMobile: `${baseNumberSmall / 0.5}vw`,
  spacingLargeMobile: `${baseNumberSmall / 0.5}vw`,
  spacingXLargeMobile: `${baseNumberSmall / 0.2}vw`,

  spacingTight: `${baseNumber / 10}vw`,
  spacingSmall: `${baseNumber / 6}vw`,
  spacingMedium: `${baseNumber / 4}vw`,
  spacingLarge: `${baseNumber / 2}vw`,
  spacingXLarge: `${baseNumber / 1}vw`,

  /* Refrences */

  spacingPageTopMobile: `${baseNumber / 2}vw`,
  spacingPageTop: `${baseNumber / 4}vw`,

  widthLogo: `${baseNumber * 1.2}vw`,
  widthLogoMobile: `${baseNumberSmall * 8}vw`,

  /**
   * Various
   */
  buttonPadding: `0 ${gutter * 5}px`,
  slimButtonPadding: `0 ${gutter * 2.5}px`,
  borderWidth: "2px",
  boxShadow: "0 0 0 6px rgba(0,0,0,0.12)",
  boxShadowLight: "0 0 3px 3px rgba(0,0,0,0.12)",
  headerHeightMobile: "60px",
  headerHeight: "6rem",
  headerHeightLoggedIn: "8rem",
  notificationsPaddingTop: "7.3rem",
};

export default variables;
