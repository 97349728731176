/* All themes */

import Color from "../interfaces/Color";

const primary = {
  green: "#00402D",
  green1: "#004029",
  green2: "#99C397",
  green3: "#D4E9D8",
  green4: "#F3F9F6",
  green5: "#1C4129",
  green6: "#26533E",
  purple: "#5E2751",
  purple2: "#BFA5B8",
  purple3: "rgba(191, 165, 184, 0.4)",
  purple4: "rgba(191, 165, 184, 0.1);",
  special: "#5C3D31",
  special2: "#F2A900",
  special3: "rgba(242, 169, 0, 0.4)",
  special4: "rgba(242, 169, 0, 0.1)",
};
const secondary = {
  lightBlue: "#0072ce",
  darkBlue: "#003C71",
  red: "#d92f36",
  pink: "#E06287",
  orange: "#FF6A39",
  yellow: "#F2A900",
  yellow2: "#FFC600",
  dark: "#000000",
  lightDark: "#202020",
  darkGray: "#808080",
  gray: "#BEBEBE",
  lightGray: "#E9E9E9",
  lighterGray: "#f5f5f5",
  lightestGray: "#FBFBFB",
  light: "#FFFFFF",

  //Updated grey as of 3.feb.2021
  grey: "#F2F5F5",
};

const grayPallet = {
  gray900: "#212121",
  gray800: "#424242",
  gray700: "#666666",
  gray600: "#757575",
  gray500: "#9E9E9E",
  gray400: "#BDBDBD",
  gray300: "#E0E0E0",
  gray200: "#EEEEEE",
  gray100: "#F5F5F5",
  gray50: "#FAFAFA",
  gray25: "#F8F8F8",
};

const greenPallet = {
  green900: "#004E42",
  green800: "#166753",
  green700: "#158462",
  green600: "#199870",
  green500: "#44B284",
  green400: "#70CC98",
  green300: "#90D8AF",
  green200: "#B0E3C6",
  green100: "#D0EFDD",
  green50: "#F0FAF4",
};

const yellowPallet = {
  yellow900: "#5C3C33",
  yellow800: "#825726",
  yellow700: "#A7731A",
  yellow600: "#CD8E0D",
  yellow500: "#F2A900",
  yellow400: "#F4B92F",
  yellow300: "#F7C95E",
  yellow200: "#F9DA8D",
  yellow100: "#FCEABC",
  yellow50: "#FEFAEB",
  yellow25: "#FAF8F5",
};

const purplePallet = {
  purple900: "#4E2142",
  purple800: "#622C54",
  purple700: "#763866",
  purple600: "#8A417A",
  purple500: "#9E4C8C",
  purple400: "#B06CA0",
  purple300: "#C08EB6",
  purple200: "#D0AECC",
  purple100: "#E2CEDE",
  purple50: "#F2EEF2",
};

const brownPallet = {
  brown900: "#5c3d31", // dark
  brown800: "#4e342e",
  brown700: "#5d4037",
  brown600: "#6d4c41",
  brown500: "#795548",
  brown400: "#8d6e63",
  brown300: "#a1887f",
  brown200: "#bcaaa4",
  brown100: "#dacebd", // less light
  brown50: "#f0ebe4", // light
};

const defaultTheme = {
  name: "default",
  sizes: {
    emptyColumnWidth: "40px",
  },

  // -------- NEW DESIGN --------
  primary: primary,
  secondary: secondary,
  // -------- NEW DESIGN --------

  colors: {
    /* Black and white */
    dark: grayPallet.gray900,
    light: "#ffffff",

    /* Color pallets */
    ...grayPallet,
    ...greenPallet,
    ...yellowPallet,
    ...purplePallet,
    ...brownPallet,

    /* Main colors */

    primary: new Color("#614B79"),
    secondary: new Color("#285C4D"),
    black: new Color("#1D1C1A"),
    white: new Color("#FFFFFF"),
    grey1: new Color("#898989"),
    grey2: new Color("#F4E5CE"),
    grey3: new Color("#FFF6E8"),
    background: new Color("#FFFDFA"),
    discount: new Color("#FF543C"),
    information: new Color("#285C4D"),
    error: new Color("#AE172D"),
    highlight: new Color("#FFCB2E"),
    hover: new Color("#553578"),
    disabled: new Color("#DFDBE4"),
    informationBG: new Color("#EAEFED"),
    errorBG: new Color("#FBF3F4"),
    highlightBG: new Color("#FFFCF5"),

    primaryDark: greenPallet.green900,
    primaryLight: greenPallet.green100,

    primaryYellow: yellowPallet.yellow500,
    primaryYellowDark: yellowPallet.yellow900,
    primaryYellowLight: yellowPallet.yellow100,

    /* Text colors */

    /* Light backgroud */
    textHightLight: grayPallet.gray900,
    textMediumLight: grayPallet.gray700,
    textDisabledLight: grayPallet.gray500,
    textLinkHoverLight: greenPallet.green700,

    /* Dark backgroud */
    textHightDark: "#FFFFFF",
    textMediumDark: grayPallet.gray500,
    textDisabledDark: grayPallet.gray700,
    textLinkHoverDark: greenPallet.green400,

    textLink: greenPallet.green600,

    /* Extra colors */
    warning: "rgba(204,51,24,0.08)",
    valid: greenPallet.green50,
    special: "#23ca34",
    messageBg: "#FCEEF0",
    disable: grayPallet.gray100,
    productRibbon: "rgba(250, 250, 250, 0.76)",
    orange: "rgba(242,168,0,1)",
    red: "rgb(220, 84, 54)",
    lmkOrange: "#f5e8da",
    orangeDelivery: "#FF6A39",
    bg: greenPallet.green50,
    border: greenPallet.green200,
    ribbonbg: greenPallet.green200,
    productCardTextColor: "",

    toasterBg: {
      info: grayPallet.gray900,
      error: "rgb(220, 84, 54)",
      warning: yellowPallet.yellow600,
      success: greenPallet.green700,
    },

    /* Buttons */
    primaryButton: greenPallet.green600,
    primaryButtonHover: greenPallet.green700,
    primaryButtonActive: "rgba(0,0,0,0.12)",
    primaryButtonDisabled: "rgba(25,152,112,0.4)",
    primaryButtonText: "#FFFFFF",
    primaryButtonDisabledText: "#FFFFFF",

    primaryBlackButton: grayPallet.gray900,
    primaryBlackButtonHover: grayPallet.gray700,
    primaryBlackButtonActive: grayPallet.gray500,
    primaryBlackButtonDisabled: "rgba(33,33,33,0.24)",
    primaryBlackButtonText: "#FFFFFF",
    primaryBlackButtonDisabledText: "#FFFFFF",

    primaryYellowButton: yellowPallet.yellow500,
    primaryYellowButtonHover: yellowPallet.yellow700,
    primaryYellowButtonActive: "rgba(0,0,0,0.12)",
    primaryYellowButtonDisabled: "rgba(25,152,112,0.4)",
    primaryYellowButtonText: "#FFFFFF",
    primaryYellowButtonDisabledText: "#FFFFFF",

    secondaryGreyButton: grayPallet.gray100,
    secondaryGreyButtonHover: grayPallet.gray200,
    secondaryGreyButtonActive: "#E0E0E0",
    secondaryGreyButtonDisabled: grayPallet.gray100,
    secondaryGreyButtonText: "rgba(0,0,0,0.6)",
    secondaryGreyButtonDisabledText: "rgba(0,0,0,0.2)",

    secondaryLightBlackButton: "#FFFFFF",
    secondaryLightBlackButtonHover: grayPallet.gray100,
    secondaryLightBlackButtonActive: grayPallet.gray200,
    secondaryLightBlackButtonDisabled: "#FFFFFF",
    secondaryLightBlackButtonText: "rgba(0,0,0,0.6)",
    secondaryLightBlackButtonDisabledText: "rgba(0,0,0,0.2)",

    secondaryLightGreenButton: "#FFFFFF",
    secondaryLightGreenButtonHover: greenPallet.green50,
    secondaryLightGreenButtonActive: "#C6EBD642",
    secondaryLightGreenButtonDisabled: "#FFFFFF",
    secondaryLightGreenButtonText: greenPallet.green600,
    secondaryLightGreenButtonDisabledText: "rgba(21,132,98,0.4)",

    /* Refrences */
    linkUnderlineStrong: "#99e8a6",
    linkUnderlineWeak: "#e1e1e1",
    headerBg: "transparent",
    switchActiveBg: "#23ca34",
  },
  typography: {
    // -------- NEW DESIGN --------
    fontSizeGlobalMin: "16px",
    fontSizeGlobalMax: "27px",
    fontSizeH1Min: "20px",
    fontSizeH1Huge: "58px",
    fontSizeH1Max: "42px",
    fontSizeH2Min: "20px",
    fontSizeH2Max: "33px",
    fontSizeH3Min: "16px",
    fontSizeH3Max: "20px",
    fontSizeTiny: "12px",
    fontSizeSmall: "14px",
    fontSizeMedium: "18px",
    fontSizeLarge: "30px",
    fontFamily: '"Raleway", Helvetica,Arial',
    fontSizeGlobalMobile: "16px",

    // FIGMA <-- Remove comment after cleanup
    fontSizeLabel: "16px",
    // -------- NEW DESIGN --------
  },
};

const themes = {
  default: defaultTheme,
  primary,
  secondary,
  green: {
    ...defaultTheme,
    name: "green",
  },
  orange: {
    ...defaultTheme,
    name: "orange",
    colors: {
      ...defaultTheme.colors,

      /* Black and white */
      dark: yellowPallet.yellow500,
      light: yellowPallet.yellow50,

      /* Main colors */
      primary: yellowPallet.yellow400,
      primaryDark: yellowPallet.yellow900,
      primaryLight: yellowPallet.yellow100,

      primaryYellow: yellowPallet.yellow500,
      primaryYellowDark: yellowPallet.yellow900,
      primaryYellowLight: yellowPallet.yellow100,

      /* Text colors */

      /* Light backgroud */
      textHightLight: grayPallet.gray900,
      textMediumLight: grayPallet.gray700,
      textDisabledLight: grayPallet.gray500,
      textLinkHoverLight: yellowPallet.yellow700,

      /* Dark backgroud */
      textHightDark: "#FFFFFF",
      textMediumDark: grayPallet.gray500,
      textDisabledDark: grayPallet.gray700,
      textLinkHoverDark: yellowPallet.yellow400,

      textLink: yellowPallet.yellow600,

      /* Extra colors */
      warning: "rgba(204,51,24,0.08)",
      valid: yellowPallet.yellow50,
      special: "#23ca34",
      error: "#CC3318",
      messageBg: "#FCEEF0",
      disable: grayPallet.gray100,
      productRibbon: "rgba(250, 250, 250, 0.76)",
      orange: "rgba(242,168,0,1)",
      red: "rgb(220, 84, 54)",
      lmkOrange: "#f5e8da",
      orangeDelivery: "#FF6A39",
      bg: yellowPallet.yellow50,
      border: yellowPallet.yellow200,
      ribbonbg: yellowPallet.yellow300,
      productCardTextColor: yellowPallet.yellow900,

      toasterBg: {
        info: grayPallet.gray900,
        // error: '#CC3318',
        error: "rgb(220, 84, 54)",
        warning: yellowPallet.yellow600,
        success: yellowPallet.yellow700,
      },

      /* Buttons */
      primaryButton: yellowPallet.yellow400,
      primaryButtonHover: yellowPallet.yellow700,
      primaryButtonActive: "rgba(0,0,0,0.12)",
      primaryButtonDisabled: yellowPallet.yellow100,
      primaryButtonText: "#FFFFFF",
      primaryButtonDisabledText: "#FFFFFF",

      primaryBlackButton: yellowPallet.yellow100,
      primaryBlackButtonHover: grayPallet.gray700,
      primaryBlackButtonActive: grayPallet.gray500,
      primaryBlackButtonDisabled: yellowPallet.yellow100,
      primaryBlackButtonText: "#FFFFFF",
      primaryBlackButtonDisabledText: "#FFFFFF",

      primaryYellowButton: yellowPallet.yellow500,
      primaryYellowButtonHover: yellowPallet.yellow700,
      primaryYellowButtonActive: "rgba(0,0,0,0.12)",
      primaryYellowButtonDisabled: "rgba(25,152,112,0.4)",
      primaryYellowButtonText: "#FFFFFF",
      primaryYellowButtonDisabledText: "#FFFFFF",

      secondaryGreyButton: grayPallet.gray100,
      secondaryGreyButtonHover: grayPallet.gray200,
      secondaryGreyButtonActive: "#E0E0E0",
      secondaryGreyButtonDisabled: grayPallet.gray100,
      secondaryGreyButtonText: "rgba(0,0,0,0.6)",
      secondaryGreyButtonDisabledText: "rgba(0,0,0,0.2)",

      secondaryLightBlackButton: "#FFFFFF",
      secondaryLightBlackButtonHover: grayPallet.gray100,
      secondaryLightBlackButtonActive: grayPallet.gray200,
      secondaryLightBlackButtonDisabled: "#FFFFFF",
      secondaryLightBlackButtonText: "rgba(0,0,0,0.6)",
      secondaryLightBlackButtonDisabledText: "rgba(0,0,0,0.2)",

      secondaryLightGreenButton: "#FFFFFF",
      secondaryLightGreenButtonHover: yellowPallet.yellow50,
      secondaryLightGreenButtonActive: "#C6EBD642",
      secondaryLightGreenButtonDisabled: "#FFFFFF",
      secondaryLightGreenButtonText: yellowPallet.yellow600,
      secondaryLightGreenButtonDisabledText: "rgba(21,132,98,0.4)",
    },
  },
  brown: {
    ...defaultTheme,
    name: "brown",
    colors: {
      ...defaultTheme.colors,

      /* Black and white */
      dark: brownPallet.brown500,
      light: brownPallet.brown50,

      /* Main colors */
      primary: brownPallet.brown400,
      primaryDark: brownPallet.brown900,
      primaryLight: brownPallet.brown100,

      primarybrown: brownPallet.brown500,
      primarybrownDark: brownPallet.brown900,
      primarybrownLight: brownPallet.brown100,

      /* Text colors */

      /* Light backgroud */
      textHightLight: grayPallet.gray900,
      textMediumLight: grayPallet.gray700,
      textDisabledLight: grayPallet.gray500,
      textLinkHoverLight: brownPallet.brown700,

      /* Dark backgroud */
      textHightDark: "#FFFFFF",
      textMediumDark: grayPallet.gray500,
      textDisabledDark: grayPallet.gray700,
      textLinkHoverDark: brownPallet.brown400,

      textLink: brownPallet.brown600,

      /* Extra colors */
      warning: "rgba(204,51,24,0.08)",
      valid: brownPallet.brown50,
      special: "#23ca34",
      error: "#CC3318",
      messageBg: "#FCEEF0",
      disable: grayPallet.gray100,
      productRibbon: "rgba(250, 250, 250, 0.76)",
      orange: "rgba(242,168,0,1)",
      red: "rgb(220, 84, 54)",
      lmkOrange: "#f5e8da",
      orangeDelivery: "#FF6A39",
      bg: brownPallet.brown50,
      border: brownPallet.brown100,
      ribbonbg: "#2C0605",
      productCardTextColor: "#FFFFFF",

      toasterBg: {
        info: grayPallet.gray900,
        error: "#CC3318",
        warning: brownPallet.brown600,
        success: brownPallet.brown700,
      },

      /* Buttons */
      primaryButton: brownPallet.brown400,
      primaryButtonHover: brownPallet.brown700,
      primaryButtonActive: "rgba(0,0,0,0.12)",
      primaryButtonDisabled: brownPallet.brown100,
      primaryButtonText: "#FFFFFF",
      primaryButtonDisabledText: "#FFFFFF",

      primaryBlackButton: brownPallet.brown500,
      primaryBlackButtonHover: grayPallet.gray700,
      primaryBlackButtonActive: grayPallet.gray500,
      primaryBlackButtonDisabled: brownPallet.brown100,
      primaryBlackButtonText: "#FFFFFF",
      primaryBlackButtonDisabledText: "#FFFFFF",

      primaryYellowButton: yellowPallet.yellow500,
      primaryYellowButtonHover: yellowPallet.yellow700,
      primaryYellowButtonActive: "rgba(0,0,0,0.12)",
      primaryYellowButtonDisabled: "rgba(25,152,112,0.4)",
      primaryYellowButtonText: "#FFFFFF",
      primaryYellowButtonDisabledText: "#FFFFFF",

      primaryBrownButton: brownPallet.brown500,
      primaryBrownButtonHover: brownPallet.brown700,
      primaryBrownButtonActive: "rgba(0,0,0,0.12)",
      primaryBrownButtonDisabled: "rgba(25,152,112,0.4)",
      primaryBrownButtonText: "#FFFFFF",
      primaryBrownButtonDisabledText: "#FFFFFF",

      secondaryGreyButton: grayPallet.gray100,
      secondaryGreyButtonHover: grayPallet.gray200,
      secondaryGreyButtonActive: "#E0E0E0",
      secondaryGreyButtonDisabled: grayPallet.gray100,
      secondaryGreyButtonText: "rgba(0,0,0,0.6)",
      secondaryGreyButtonDisabledText: "rgba(0,0,0,0.2)",

      secondaryLightBlackButton: "#FFFFFF",
      secondaryLightBlackButtonHover: grayPallet.gray100,
      secondaryLightBlackButtonActive: grayPallet.gray200,
      secondaryLightBlackButtonDisabled: "#FFFFFF",
      secondaryLightBlackButtonText: "rgba(0,0,0,0.6)",
      secondaryLightBlackButtonDisabledText: "rgba(0,0,0,0.2)",

      secondaryLightGreenButton: "#FFFFFF",
      secondaryLightGreenButtonHover: yellowPallet.yellow50,
      secondaryLightGreenButtonActive: "#C6EBD642",
      secondaryLightGreenButtonDisabled: "#FFFFFF",
      secondaryLightGreenButtonText: yellowPallet.yellow600,
      secondaryLightGreenButtonDisabledText: "rgba(21,132,98,0.4)",
    },
  },
  purple: {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,

      /* Black and white */
      dark: purplePallet.purple500,
      light: purplePallet.purple50,

      /* Main colors */
      primary: purplePallet.purple400,
      primaryDark: purplePallet.purple900,
      primaryLight: purplePallet.purple100,

      primarybrown: purplePallet.purple500,
      primarybrownDark: purplePallet.purple900,
      primarybrownLight: purplePallet.purple100,

      /* Text colors */

      /* Light backgroud */
      textHightLight: grayPallet.gray900,
      textMediumLight: grayPallet.gray700,
      textDisabledLight: grayPallet.gray500,
      textLinkHoverLight: purplePallet.purple700,

      /* Dark backgroud */
      textHightDark: "#FFFFFF",
      textMediumDark: grayPallet.gray500,
      textDisabledDark: grayPallet.gray700,
      textLinkHoverDark: purplePallet.purple400,

      textLink: purplePallet.purple600,

      /* Extra colors */
      warning: "rgba(204,51,24,0.08)",
      valid: purplePallet.purple50,
      special: "#23ca34",
      error: "#CC3318",
      messageBg: "#FCEEF0",
      disable: grayPallet.gray100,
      productRibbon: "rgba(250, 250, 250, 0.76)",
      orange: "rgba(242,168,0,1)",
      red: "rgb(220, 84, 54)",
      lmkOrange: "#f5e8da",
      orangeDelivery: "#FF6A39",
      bg: purplePallet.purple50,
      border: purplePallet.purple200,
      ribbonbg: "#2C0605",
      productCardTextColor: "#FFFFFF",

      toasterBg: {
        info: grayPallet.gray900,
        error: "#CC3318",
        warning: purplePallet.purple600,
        success: purplePallet.purple700,
      },

      /* Buttons */
      primaryButton: purplePallet.purple400,
      primaryButtonHover: purplePallet.purple700,
      primaryButtonActive: "rgba(0,0,0,0.12)",
      primaryButtonDisabled: purplePallet.purple100,
      primaryButtonText: "#FFFFFF",
      primaryButtonDisabledText: "#FFFFFF",

      primaryBlackButton: purplePallet.purple500,
      primaryBlackButtonHover: grayPallet.gray700,
      primaryBlackButtonActive: grayPallet.gray500,
      primaryBlackButtonDisabled: purplePallet.purple100,
      primaryBlackButtonText: "#FFFFFF",
      primaryBlackButtonDisabledText: "#FFFFFF",

      primaryYellowButton: yellowPallet.yellow500,
      primaryYellowButtonHover: yellowPallet.yellow700,
      primaryYellowButtonActive: "rgba(0,0,0,0.12)",
      primaryYellowButtonDisabled: "rgba(25,152,112,0.4)",
      primaryYellowButtonText: "#FFFFFF",
      primaryYellowButtonDisabledText: "#FFFFFF",

      primaryBrownButton: purplePallet.purple500,
      primaryBrownButtonHover: purplePallet.purple700,
      primaryBrownButtonActive: "rgba(0,0,0,0.12)",
      primaryBrownButtonDisabled: "rgba(25,152,112,0.4)",
      primaryBrownButtonText: "#FFFFFF",
      primaryBrownButtonDisabledText: "#FFFFFF",

      secondaryGreyButton: grayPallet.gray100,
      secondaryGreyButtonHover: grayPallet.gray200,
      secondaryGreyButtonActive: "#E0E0E0",
      secondaryGreyButtonDisabled: grayPallet.gray100,
      secondaryGreyButtonText: "rgba(0,0,0,0.6)",
      secondaryGreyButtonDisabledText: "rgba(0,0,0,0.2)",

      secondaryLightBlackButton: "#FFFFFF",
      secondaryLightBlackButtonHover: grayPallet.gray100,
      secondaryLightBlackButtonActive: grayPallet.gray200,
      secondaryLightBlackButtonDisabled: "#FFFFFF",
      secondaryLightBlackButtonText: "rgba(0,0,0,0.6)",
      secondaryLightBlackButtonDisabledText: "rgba(0,0,0,0.2)",

      secondaryLightGreenButton: "#FFFFFF",
      secondaryLightGreenButtonHover: yellowPallet.yellow50,
      secondaryLightGreenButtonActive: "#C6EBD642",
      secondaryLightGreenButtonDisabled: "#FFFFFF",
      secondaryLightGreenButtonText: yellowPallet.yellow600,
      secondaryLightGreenButtonDisabledText: "rgba(21,132,98,0.4)",
    },
  },
};

// we need to fix yellow theme change orange themes name to yellow
themes.yellow = {
  ...themes.orange,
  name: "yellow",
};

export default themes;
