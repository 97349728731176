import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";

import getWidth from "../helpers/getWidth";

const GridItem = styled.div`
  display: inline-block;
  width: 100%;
  padding-left: ${variables.gutterMediumMobile};
  vertical-align: top;
  box-sizing: border-box;

  ${media.teen`
    padding-left: ${variables.gutterMedium};
  `};

  /*
   * Item widths
   */

  /* Base width */
  ${(props) =>
    props.base &&
    css`
      width: ${getWidth(props.base)};
    `};

  /* Infant width */
  ${(props) =>
    props.infant &&
    css`
      ${media.infant`
        width: ${getWidth(props.infant)};
      `};
    `};

  /* Baby width */
  ${(props) =>
    props.baby &&
    css`
      ${media.baby`
        width: ${getWidth(props.baby)};
      `};
    `};

  /* Child width */
  ${(props) =>
    props.child &&
    css`
      ${media.child`
        width: ${getWidth(props.child)};
      `};
    `};

  /* Teen width */
  ${(props) =>
    props.teen &&
    css`
      ${media.teen`
        width: ${getWidth(props.teen)};
      `};
    `};

  /* Adult width */
  ${(props) =>
    props.adult &&
    css`
      ${media.adult`
        width: ${getWidth(props.adult)};
      `};
    `};

  /* Elder width */
  ${(props) =>
    props.elder &&
    css`
      ${media.elder`
        width: ${getWidth(props.elder)};
      `};
    `};

  /*
   * Item push settings
   */

  /* Base push */
  ${(props) =>
    props.basePush &&
    css`
      position: relative;
      left: ${getWidth(props.basePush)};
    `};

  /* Infant push */
  ${(props) =>
    props.infantPush &&
    css`
      ${media.teen`
        position: relative;
        left: ${getWidth(props.infantPush)};
      `};
    `};

  /* Baby push */
  ${(props) =>
    props.babyPush &&
    css`
      ${media.teen`
        position: relative;
        left: ${getWidth(props.babyPush)};
      `};
    `};

  /* Child push */
  ${(props) =>
    props.childPush &&
    css`
      ${media.teen`
        position: relative;
        left: ${getWidth(props.childPush)};
      `};
    `};

  /* Teen push */
  ${(props) =>
    props.teenPush &&
    css`
      ${media.teen`
        position: relative;
        left: ${getWidth(props.teenPush)};
      `};
    `};

  /* Adult push */
  ${(props) =>
    props.adultPush &&
    css`
      ${media.teen`
        position: relative;
        left: ${getWidth(props.adultPush)};
      `};
    `};

  /* Elder push */
  ${(props) =>
    props.elderPush &&
    css`
      ${media.teen`
        position: relative;
        left: ${getWidth(props.elderPush)};
      `};
    `};

  /* Enable stickiness */
  ${(props) =>
    props.sticky &&
    css`
      @media ${variables.screen.medium} {
        position: sticky;
        top: ${props.sticky};
      }
    `};

  ${(props) =>
    props.posRelative &&
    css`
      position: relative;
    `};

  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `};
`;

export default GridItem;
