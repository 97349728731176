import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";

const Image = styled.img`
  max-width: 100%;

  /* Full-width version */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};
  /* Icon version */
  ${(props) =>
    props.smallIcon &&
    css`
      width: 20px;
      height: 20px;
    `};

  /* Icon version */
  ${(props) =>
    props.icon &&
    css`
      width: 29px;
      height: 29px;
    `};

  /* Large Icon version */
  ${(props) =>
    props.largeIcon &&
    css`
      width: 60px;
      height: 60px;

      ${media.teen`
        margin-right: ${variables.gutterTight};
      `};
    `};

  /* Icon version */
  ${(props) =>
    props.large &&
    css`
      width: 100px;
      height: 80px;
    `};

  /* Icon version */
  ${(props) =>
    props.veryLarge &&
    css`
      width: 300px;
      height: 220px;
    `};

  ${(props) =>
    props.xl &&
    css`
      width: 100px;
      height: 80px;

      ${media.teen`
        margin-right: ${variables.gutterTight};
      `};
    `};

  ${(props) =>
    props.animation &&
    css`
      animation: ${props.animation} 0.2s infinite ease-in-out forwards alternate;
    `};

  ${(props) =>
    props.fit &&
    css`
      width: 100%;
      min-height: 100%;
      object-fit: cover;
    `};

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `};

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `};

  ${(props) =>
    props.blur &&
    css`
      filter: blur(10px);
    `};
  /* No-maxwidth version */
  ${(props) =>
    props.noMax &&
    css`
      max-width: none;
    `};

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export default Image;
