import React from "react";
import Link from "next/link";

import { ExternalLink } from "../../primitives";

const deleteProps = (props, list) =>
  Object.keys(props).reduce((object, key) => {
    if (!list.find((item) => key === item)) {
      object[key] = props[key];
    }
    return object;
  }, {});

const ServerLink = (props) => {
  const { href, children, as } = props;
  const styledProps = deleteProps(props, ["href", "children", "as"]);

  return (
    <Link href={href} as={as} passHref legacyBehavior>
      <ExternalLink {...styledProps}>
        <>{children}</>
      </ExternalLink>
    </Link>
  );
};

export default ServerLink;
