import styled, { css } from "styled-components";

const Strong = styled.strong`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;

  ${(props) =>
    props.center &&
    css`
      margin: 0 auto;
    `};

  /* Faded variant */
  ${(props) =>
    props.warning &&
    css`
      color: ${props.theme.colors.red};
    `};
  ${(props) =>
    props.center &&
    css`
      margin: 0 auto;
    `};

  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.primary};
    `};

  ${(props) =>
    props.orange &&
    css`
      color: ${props.theme.secondary.orange};
    `};

  ${(props) =>
    props.medium &&
    css`
      font-weight: 500;
    `};

  ${(props) =>
    props.heavy &&
    css`
      font-weight: 700;
    `};

  ${(props) =>
    props.price &&
    css`
      font-size: 18px;
      line-height: 18px;
    `};
  ${(props) =>
    props.priceSize &&
    css`
      font-size: 18px;
    `};

  ${(props) =>
    props.mediumWeight &&
    css`
      font-weight: 500;
      line-height: 18px;
    `};

  ${(props) =>
    props.label &&
    css`
      font-size: 16px;
      line-height: 16px;
    `};

  ${(props) =>
    props.tag &&
    css`
      line-height: 14px;
    `};

  ${(props) =>
    props.tagStrong &&
    css`
      font-size: 14px;
      line-height: 14px;
    `};

  ${(props) =>
    props.large &&
    css`
      font-size: 16px;
      font-weight: 500;
    `};

  ${(props) =>
    props.tiny &&
    css`
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 17px;
    `};
`;

export default Strong;
