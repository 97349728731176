export default class Color {
  constructor(hex, a = 1) {
    const r = "0x" + hex[1] + hex[2];
    const g = "0x" + hex[3] + hex[4];
    const b = "0x" + hex[5] + hex[6];

    if (hex[7]) {
      a = "0x" + hex[7] + hex[8];
      a = +(a / 255).toFixed(3);

      this.hex = hex;
    } else {
      let xa = Math.round(a * 255)
        .toString(16)
        .toUpperCase();

      if (xa.length === 1) {
        xa = "0" + xa;
      }

      this.hex = `${hex}${xa}`;
    }

    this.r = +r;
    this.g = +g;
    this.b = +b;
    this.a = +a;
  }

  toString() {
    return this.asRGBA;
  }

  get asHex() {
    return this.hex;
  }

  get asRGBA() {
    return `rgba(${+this.r}, ${+this.g}, ${+this.b}, ${+this.a})`;
  }
}
