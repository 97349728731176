import styled from "styled-components";

const Kr = styled.span`
  font-size: inherit !important;
  &:after {
    font-size: 0.6em;
    content: " kr";
  }
`;

export default Kr;
