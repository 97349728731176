import styled, { css } from "styled-components";

import { media } from "../global-styles";

const Flex = styled.div`
  display: flex;
  flex-direction: row;

  /* Full-width version */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};

  /* Align items settings */
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};

  /* Justify content settings */
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};

  /* Justify content settings */
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};

  /* Wrap settings */
  ${(props) =>
    props.isWrap &&
    css`
      flex-wrap: wrap;
    `};

  ${(props) =>
    props.responsive &&
    css`
      flex-direction: column;
      ${media.teen`
        flex-direction: row;
      `};
    `};

  ${(props) =>
    props.fullWidthHeight &&
    css`
      height: 100%;
      width: 100%;
    `};

  ${(props) =>
    props.centeredMobile &&
    css`
      justify-content: center;
      align-items: center;
      ${media.child`
          justify-content: start;
      `};
    `};
`;

export default Flex;
