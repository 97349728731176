import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";

const Ul = styled.ul`
  margin: 0;

  > li {
    list-style-type: none;
  }

  /* Vspaced variant */
  ${(props) =>
    props.vspaced &&
    css`
      > li {
        margin-top: 5px;
      }
    `};

  /* Small variant */
  ${(props) =>
    props.small &&
    css`
      font-size: 16px;
    `};

  /* Bulleted variant */
  ${(props) =>
    props.bulleted &&
    css`
      > li {
        list-style-type: disc;
      }
    `};

  /* Indented variant */
  ${(props) =>
    props.indented &&
    css`
      padding-left: ${variables.gutterTightMobile};

      ${media.teen`
        padding-left: ${variables.gutterTight};
      `};
    `};

  /* Underline variant */
  ${(props) =>
    props.underLine &&
    css`
      > li {
        border-bottom: ${variables.borderWidth} solid ${props.theme.colors.bg};
      }
    `};
  /* Custom line-height variant */
  ${(props) =>
    props.lineHeight &&
    css`
      > li {
        line-height: ${props.lineHeight};
      }
    `};
`;

export default Ul;
