import styled, { css } from "styled-components";

const Small = styled.span`
  display: inline-block;
  font-size: 14px;

  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.secondary.gray};
    `};

  ${(props) =>
    props.faded700 &&
    css`
      color: ${props.theme.colors.gray700};
    `};

  ${(props) =>
    props.extraSmall &&
    css`
      font-size: 10px;
      font-weight: 500;
    `};

  ${(props) =>
    props.caption &&
    css`
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      /* identical to box height, or 100% */

      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      line-height: 10px;
    `};

  ${(props) =>
    props.medium &&
    css`
      font-weight: 500;
    `};
`;

export default Small;
