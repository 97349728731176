import styled from "styled-components";
import { variables } from "../global-styles";

const StaticGrid = {};

const Container = styled.div`
  margin: ${(props) => (props.gutter || 12) * variables.gutter}px;
`;

const Row = styled.div`
  display: flex;
  margin: 0 -${variables.gutter * 5}px;
  margin-bottom: ${(props) => (props.gutter || 2) * variables.gutter}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Item = styled.div`
  margin: 0 ${variables.gutter * 5}px;
`;

StaticGrid.Container = Container;
StaticGrid.Row = Row;
StaticGrid.Item = Item;

export default StaticGrid;
