import React from "react";

import { useMeQuery } from "@chef/state-management";

import Body from "./styles";

const AppBody = ({ children }) => {
  const { data: isLoggedIn } = useMeQuery();

  return <Body isApp={isLoggedIn}>{children}</Body>;
};

export default AppBody;

React.PureComponent.componentDidUpdate = (prevProps) => {
  Object.keys(prevProps).forEach((key) => {
    if (prevProps[key] !== this.props[key]) {
      // eslint-disable-next-line
      console.warn(
        `property ${key} changed from ${prevProps[key]} to ${this.props[key]}`,
      );
    }
  });
};
