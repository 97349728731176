import styled, { css } from "styled-components";
import fluidUnit from "../helpers/fluidUnit";
import variables from "../constants/variables";

const Text = styled.span`
  ${(props) =>
    props.upperCase &&
    css`
      text-transform: uppercase;
    `}

  ${(props) =>
    props.strikeThrough &&
    css`
      text-decoration: line-through;
    `}

  ${(props) =>
    props.green &&
    css`
      color: ${(props) => props.theme.primary.green};
    `}

    ${(props) =>
    props.green2 &&
    css`
      color: ${(props) => props.theme.primary.green2};
    `}

  ${(props) =>
    props.black &&
    css`
      color: black;
    `}

    ${(props) =>
    props.white &&
    css`
      color: white !important;
    `}

  ${(props) =>
    props.orange &&
    css`
      color: ${(props) => props.theme.secondary.orange};
    `}

  ${(props) =>
    props.yellow &&
    css`
      color: ${(props) => props.theme.secondary.yellow};
    `}

  ${(props) =>
    props.gray &&
    css`
      color: #bebebe;
    `}

    ${(props) =>
    props.lightDark &&
    css`
      color: ${(props) => props.theme.secondary.lightDark};
    `}

  ${(props) =>
    props.red &&
    css`
      color: ${(props) => props.theme.secondary.orange};
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: ${variables.fontSize.small};
    `}

  ${(props) =>
    props.regular &&
    css`
      font-size: ${variables.fontSize.regular};
    `}

    ${(props) =>
    props.larger &&
    css`
      font-size: ${variables.fontSize.larger};
    `};

  ${(props) =>
    props.large &&
    css`
      font-size: 42px;
      line-height: 26px;
    `}

  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${(props) =>
    props.normal &&
    css`
      font-weight: normal;
    `}

  ${(props) =>
    props.thin &&
    css`
      font-weight: 300;
    `}

  ${(props) =>
    props.limitWidth &&
    css`
      display: inline-block;
      max-width: 650px;
    `}


  ${(props) =>
    props.tiny &&
    css`
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
    `}

  ${(props) =>
    props.strong &&
    css`
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
    `}

    ${(props) =>
    props.regular &&
    css`
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.02em;
    `}

      ${(props) =>
    props.newSmall &&
    css`
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
    `}

      ${(props) =>
    props.discount &&
    css`
      color: #ff6a39;
    `}


  ${(props) => props.altFont && css``}
`;

const Large = styled.strong`
  ${fluidUnit({
    unit: "font-size",
    minUnit: "20px",
    maxUnit: "40px",
  })};
  font-weight: bold;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.dark};

  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};

  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.primary};
    `};
`;

const LargeUnderline = styled(Large)`
  padding: 0 5px;
  border-bottom: 3px solid ${(props) => props.theme.colors.primary};
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const StrikeThrough = styled.span`
  text-decoration: line-through;
`;

const OpacityText = styled.span`
  color: ${(props) => props.theme.colors.dark};
  opacity: 0.6;
`;

const ColorText = styled.span`
  /* color variant */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `};
`;

const Label = styled.p`
  font-size: ${(props) => props.theme.typography.fontSizeLabel};
  font-weight: 600;
`;

const Price = styled.p`
  font-size: ${(props) => props.theme.typography.fontSizeMedium};
  line-height: ${(props) => props.theme.typography.fontSizeMedium};
  letter-spacing: 0.02em;
  font-familiy: ${(props) => props.theme.typography.fontFamily};
  font-weight: 600;
`;

export {
  Large,
  LargeUnderline,
  NoWrap,
  StrikeThrough,
  OpacityText,
  ColorText,
  Label,
  Price,
};

export default Text;
