import styled, { css } from "styled-components";

const LargeText = styled.h4`
  font-size: 16px;
  // font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.3px;

  /* Faded variant */
  ${(props) =>
    props.faded &&
    css`
      color: ${props.theme.colors.gray700};
    `};

  /* Faded700 variant */
  ${(props) =>
    props.faded700 &&
    css`
      color: ${props.theme.colors.gray700};
    `};
`;

export default LargeText;
