import styled from "styled-components";

import { variables, media } from "../global-styles";

const PageWrapper = styled.div`
  ${media.teen`
    padding-left: calc((100vw - (${variables.maxWidth} + (${variables.gutterMediumMobile} * 2))) / 2);
    padding-right: calc((100vw - (${variables.maxWidth} + (${variables.gutterMediumMobile} * 2))) / 2);
    margin: 0 ${variables.gutterMediumMobile};
    width: calc(100% - (${variables.gutterMediumMobile} * 2));
    `};
`;

export default PageWrapper;
