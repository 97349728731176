import { PropsWithChildren } from "react";
import { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";
import "core-js/stable/array/flat-map";
import "core-js/stable/array/flat";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/sv";
import "intersection-observer";
import "global.css";

import { Datadog } from "@chef/feature-datadog";
import { DiscountsProvider } from "@chef/state-management/components";
import { storeWrapper } from "@chef/state-management";
import { DebugProvider } from "@chef/feature-debug";
import { RecipeModal } from "@chef/feature-recipe";
import { TrackingProvider } from "@chef/feature-tracking";
import { Notifications } from "@chef/feature-notifications";
import { MobileAppProvider } from "@chef/feature-mobile-app";
import { useOutlineListener } from "@chef/hooks";
import PreviewProvider from "@chef/feature-sanity/PreviewProvider";
import { gtFix } from "@chef/utils/patch";

import GlobalStyles from "../global-styles";
import themes from "../constants/themes";
import Loader from "../components/Loader";
import AppBody from "../components/AppBody";

gtFix();

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <MobileAppProvider>
      <TrackingProvider>
        <DiscountsProvider>
          <Datadog />
          <ThemeProvider theme={themes["default"]}>
            <Notifications position="top-center" />
            <DebugProvider>{children}</DebugProvider>
          </ThemeProvider>
        </DiscountsProvider>
      </TrackingProvider>
    </MobileAppProvider>
  );
};

function LinasApp({ pageProps, Component, router }: AppProps) {
  useOutlineListener();

  if (router.pathname.startsWith("/studio")) {
    return <Component {...pageProps} />;
  }

  return (
    <PreviewProvider draftMode={pageProps.draftMode}>
      <Providers>
        <AppBody>
          <div className="flex flex-col min-h-screen">
            <Component {...pageProps} />

            <RecipeModal Loader={Loader} />
          </div>
        </AppBody>
        <GlobalStyles />
      </Providers>
    </PreviewProvider>
  );
}

export default storeWrapper.withRedux(LinasApp);
