import styled, { css } from "styled-components";
import variables from "../constants/variables";
import { media } from "../global-styles";

// The size map decides what gutters/spacing the Block gets
const spaces = {
  0.5: "4px",
  1: "8px",
  1.5: "12px",
  2: "16px",
  3: "24px",
  4: "32px",
  5: "40px",
  6: "48px",
  7: "56px",
  8: "64px",
  9: "72px",
};

const getSpacing = (space = 1) => spaces[space];

const SpaceBlock = styled.div`
  /* Left margin */
  ${(props) =>
    props.left &&
    css`
      margin-left: ${getSpacing(props.left)};
    `};

  /* Left custom margin */
  ${(props) =>
    props.leftCustom &&
    css`
      margin-left: ${props.leftCustom};
    `};

  /* Left Desktop margin */
  ${(props) =>
    props.leftDesktop &&
    css`
      ${media.teen`
        margin-left: ${getSpacing(props.leftDesktop)};
      `};
    `};

  /* Left Custom Desktop  margin */
  ${(props) =>
    props.leftDesktopCustom &&
    css`
      ${media.teen`
          margin-left: ${props.leftDesktopCustom};
        `};
    `};
  /* Right margin */
  ${(props) =>
    props.right &&
    css`
      margin-right: ${getSpacing(props.right)};
    `};

  /* Right custom margin */
  ${(props) =>
    props.rightCustom &&
    css`
      margin-right: ${props.rightCustom};
    `};

  /* Right Desktop margin */
  ${(props) =>
    props.rightDesktop &&
    css`
      ${media.teen`
        margin-right: ${getSpacing(props.rightDesktop)};
      `};
    `};

  /* Right Desktop Custom margin */
  ${(props) =>
    props.rightDesktopCustom &&
    css`
      ${media.teen`
          margin-right: ${props.rightDesktopCustom};
          `};
    `};
  /* Top padding */
  ${(props) =>
    props.top &&
    css`
      padding-top: ${getSpacing(props.top)};
    `};

  /* Top custom padding */
  ${(props) =>
    props.topCustom &&
    css`
      padding-top: ${props.topCustom};
    `};

  /* Top custom padding */
  ${(props) =>
    props.topDesktop &&
    css`
      ${media.teen`
        padding-top: ${getSpacing(props.topDesktop)};
      `};
    `};

  /* Top custom padding */
  ${(props) =>
    props.topDesktopCustom &&
    css`
      ${media.teen`
        padding-top: ${props.topDesktopCustom};
      `};
    `};

  /* Bottom padding */
  ${(props) =>
    props.bottom &&
    css`
      padding-bottom: ${getSpacing(props.bottom)};
    `};

  /* Bottom custom padding */
  ${(props) =>
    props.bottomCustom &&
    css`
      padding-bottom: ${props.bottomCustom};
    `};

  /* Bottom desktop padding */
  ${(props) =>
    props.bottomDesktop &&
    css`
      ${media.teen`
        padding-bottom: ${getSpacing(props.bottomDesktop)};
      `};
    `};

  /* Bottom desktop custom padding */
  ${(props) =>
    props.bottomDesktopCustom &&
    css`
      ${media.teen`
        padding-bottom: ${props.bottomDesktopCustom};
      `};
    `};

  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
    `};

  /* Text align settings */
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `};

  ${(props) =>
    props.site &&
    css`
      position: relative;
      padding-left: ${variables.gutterMediumMobile};
      padding-right: ${variables.gutterMediumMobile};
      ${media.teen`
          padding-left: calc((100vw - (${variables.maxWidth} + (${variables.gutterMediumMobile} * 2))) / 2);
          padding-right: calc((100vw - (${variables.maxWidth} + (${variables.gutterMediumMobile} * 2))) / 2);
          margin: 0 ${variables.gutterMediumMobile};
          width: calc(100% - (${variables.gutterMediumMobile} * 2));
          //padding-left: ${variables.gutterLarge};
          //padding-right: ${variables.gutterLarge};
        `};
    `};
`;

export default SpaceBlock;
