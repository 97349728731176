import styled, { css } from "styled-components";
import { variables, media } from "../global-styles";

import { fadeIn } from "../helpers/animations";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  margin: 0;
  padding: 0;
  margin-left: -${variables.gutterMediumMobile};

  ${media.teen`
    margin-left: -${variables.gutterMedium};
  `};

  /* Reversed version */
  ${(props) =>
    props.rev &&
    css`
      direction: rtl;
      text-align: left;

      > * {
        direction: ltr !important;
        text-align: left !important;
      }
    `};

  /* Full version */
  ${(props) =>
    props.full &&
    css`
      margin-left: 0;

      > * {
        padding-left: 0 !important;
      }
    `};

  /* Vertical align: middle version */
  ${(props) =>
    props.middle &&
    css`
      > * {
        vertical-align: middle !important;
      }
    `};

  /* Vertical align: bottom version */
  ${(props) =>
    props.bottom &&
    css`
      > * {
        vertical-align: bottom !important;
      }
    `};

  /* Centered version */
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
      > * {
        text-align: left !important;
      }
    `};

  /* Narrow version */
  ${(props) =>
    props.narrow &&
    css`
      margin-left: -${variables.gutterTightMobile};
      > * {
        padding-left: ${variables.gutterTightMobile} !important;
      }

      ${media.teen`
        margin-left: -${variables.gutterMedium};

        > * {
          padding-left: ${variables.gutterTight} !important;
        }
      `};
    `};

  /* Narrow version */
  ${(props) =>
    props.narrower &&
    css`
      margin-left: -${variables.gutterTightMobile};
      > * {
        padding-left: 8px !important;
      }

      ${media.teen`

        margin-left: 12px;

        > * {
          padding-left: 8px !important;
        }
      `};
    `};

  /* Narrow version */
  ${(props) =>
    props.tight &&
    css`
      margin-left: -16px !important;
      > * {
        padding-left: 16px !important;
      }
    `};

  ${(props) =>
    props.fadeIn &&
    css`
      animation ${fadeIn} 0.4s ease-in-out 1 both;
  `};
  ${(props) =>
    props.posRelative &&
    css`
      position: relative;
    `};

  ${(props) =>
    props.block &&
    css`
      display: block;
    `};

  ${(props) =>
    props.stickyContent &&
    css`
      display: flex;
      align-items: end;
    `};
`;

export default Grid;
