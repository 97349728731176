import styled, { css } from "styled-components";

const Section = styled.section`
  /* Faded variant */
  ${(props) =>
    props.bg &&
    css`
      background: ${props.theme.colors.bg};
    `};
  ${(props) =>
    props.lightGray &&
    css`
      background: ${props.theme.secondary.lighterGray};
    `};

  ${(props) =>
    props.white &&
    css`
      background: white;
    `};

  ${(props) =>
    props.redBorder &&
    css`
      color: ${props.theme.colors.red};
      border: 1px solid ${props.theme.colors.red};
    `};
  ${(props) =>
    props.color &&
    css`
      background-color: ${(props) => props.theme.colors[`${props.color}25`]};
    `};

  ${(props) =>
    props.light &&
    css`
      background: ${props.theme.colors.light};
    `};

  ${(props) =>
    props.yellow50 &&
    css`
      background-color: ${props.theme.colors.yellow50};
    `};
  ${(props) =>
    props.gray &&
    css`
      background-color: ${props.theme.secondary.lightestGray};
    `};
  ${(props) =>
    props.grayOpacity &&
    css`
      background-color: rgba(190, 190, 190, 0.05);
    `};

  ${(props) =>
    props.lighterGray &&
    css`
      background-color: ${props.theme.secondary.lighterGray};
    `};
  ${(props) =>
    props.lightestGray &&
    css`
      background-color: ${props.theme.secondary.lightestGray};
    `};

  ${(props) =>
    props.gray25 &&
    css`
      background-color: ${props.theme.colors.gray25};
    `};
  ${(props) =>
    props.grayBorder &&
    css`
      border: 1px solid ${props.theme.secondary.lightGray};
    `};
  ${(props) =>
    props.redBorder &&
    css`
      border: 1px solid ${props.theme.colors.red};
    `};
  ${(props) =>
    props.greenBorder &&
    css`
      border: 1px solid ${props.theme.colors.green200};
    `};

  ${(props) =>
    props.green &&
    css`
      background-color: ${props.theme.primary.green};
    `};

  ${(props) =>
    props.greenOpacity &&
    css`
      background-color: rgba(0, 64, 41, 0.05);
    `};
  ${(props) =>
    props.green1 &&
    css`
      background-color: ${props.theme.primary.green1};
    `};
  ${(props) =>
    props.green2 &&
    css`
      background-color: ${props.theme.primary.green2};
    `};
  ${(props) =>
    props.green3 &&
    css`
      background-color: ${props.theme.primary.green3};
    `};

  ${(props) =>
    props.warning &&
    css`
      color: #d92f36;
      background-color: #fff5f5;
    `};

  ${(props) =>
    props.opacity &&
    css`
      opacity: 0.05;
    `};

  ${(props) =>
    props.secondary &&
    css`
      background-color: ${props.theme.colors.green50};
    `};

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    `};

  ${(props) =>
    props.fullWidthHeight &&
    css`
      height: 100%;
      width: 100%;
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.borderRadius &&
    css`
      border-radius: 2px;
    `};

  ${(props) =>
    props.grey &&
    css`
      background: ${props.theme.secondary.grey};
    `};
`;

export default Section;
