import { createGlobalStyle } from "styled-components";
import variables from "./constants/variables";
import media from "./helpers/media";

/* Global styles */

const globalStyles = createGlobalStyle`
  /* Allow drawer to pass over intercom bubble */
  #intercom-container {
    z-index: 150 !important;
  }

  /* Global prefill styling for webkit (remove yellow fill) */
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }


  a {
    margin: 0;
    font-weight: normal;
  }

  a {
    cursor: pointer;
  }

  /* Global general styles */
  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  // a {
  //   cursor: pointer;
  //   text-decoration: none;
  // }

  ul, li {
    margin: 0;
    padding: 0;
  }



  // :focus{
  //   outline: 0;
  //   box-shadow: ${variables.boxShadow};
  // }

  // .noFocus{
  //   outline: 0;
  //   box-shadow: none;
  // }

  /* Helpers */
  .screenReaders {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

export default globalStyles;
export { variables, media };
